import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import { RegistriesIdEnum, RegistriesNameEnum } from '../../enums/Registries';

const IdentityDigital = () => {
  const url: string = '/domains/addBulkCommon';
  return (
    <MainCard title={RegistriesNameEnum.IdentityDigital}>
      <AddDomainsContent registryId={RegistriesIdEnum.IdentityDigital} registryName={RegistriesNameEnum.IdentityDigital} url={url} />
    </MainCard>
  );
};

export default IdentityDigital;
